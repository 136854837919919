//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'PostEditor',
    data() {
        return {
            postBody: '',
            pastPostBody: '',
            imagesToDetach: [],
            isLoading: true,
            hasSharedEntity: false,
            medias: [],
            videoTypes: [
                'mp4'
            ],
        }
    },
    created() {
        this.$http({
            url: 'posts/' + this.$route.params.id,
            method: 'GET'
        }).then((response) => {
            this.isLoading = false;
            switch (response.data['status']) {
                case 'post_retrieved':
                    this.postBody = response.data['body']['post']['body'];
                    this.medias = response.data['body']['medias'];
                    this.pastPostBody = this.postBody;
                    if (response.data['body']['post']['shared_entity_id'] !== null) {
                        this.hasSharedEntity = true;
                    }
                break;
            }
        }).catch((err) => {
            if (err.response.status == 404) {
                this.$router.push({name: 'NotFound404'});
            }
        })
    },
    methods: {
        isVideo(media) {
            if (this.videoTypes.includes(media.type)) {
                return true;
            }

            return false;
        },
        convertMediaToUrl(media, isThumbnail = false) {
            if (isThumbnail) {
                if (!this.isVideo(media)) {
                    return process.env.VUE_APP_APISERVER + 'gb_medias/'
                    + media.user_id + '/thumb_' + media.file_name + '.' + media.type;
                }
            }
                
            return process.env.VUE_APP_APISERVER + 'gb_medias/'
            + media.user_id + '/' + media.file_name + '.' + media.type;
        },
        removeMedia(id) {
            this.imagesToDetach.push(id);
            this.medias = this.medias.filter((media) => media.id !== id);
        },
        save() {
            if (this.imagesToDetach.length > 0 || this.pastPostBody != this.postBody) {
                this.$q.dialog({
                    title: 'Save Changes?',
                    message: 'Are you sure that you want to save your changes for this post?',
                    cancel: true,
                    persistent: true
                }).onOk(() => {
                    this.$http({
                        url: 'posts/' + this.$route.params.id,
                        method: 'PUT',
                        data: {
                            body: this.postBody,
                            imagesToDetach: this.imagesToDetach
                        }
                    }).then((response) => {
                        this.isLoading = false;
                        switch (response.data['status']) {
                            case 'post_updated':
                                this.$router.push({name: 'Post', params: {id: this.$route.params.id}});
                            break;
                        }
                    }).catch((err) => {
                        if (err.response.status == 404) {
                            this.$router.push({name: 'NotFound404'});
                        }
                        if (err.response.status == 500) {
                            this.$q.notify({
                                message: 'An error has occurred while trying to save. Please try again.',
                                color: 'negative'
                            });
                        }
                    })
                }).onCancel(() => {
                    //
                }).onDismiss(() => {
                    //
                })
            } else {
                this.$router.push({name: 'Post', params: {id: this.$route.params.id}});
            }
        }
    }
}
